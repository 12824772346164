import {
    AUTH_LOGIN,
    AUTH_CHECK,
    AUTH_GET_PERMISSIONS,
    AUTH_LOGOUT,
    AUTH_ERROR
} from 'react-admin'

import SettingsStore from '../store/SettingsStore'

const { apiUrl } = SettingsStore.state

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params

        let url = `${apiUrl}/login/adminlogin`

        let credentials = { username, password }
        let formBody = []

        for (let property in credentials) {
            let encodedKey = encodeURIComponent(property)
            let encodedValue = encodeURIComponent(credentials[property])
            formBody.push(encodedKey + '=' + encodedValue)
        }

        let options = {
            method: 'POST',
            body: formBody.join('&'),
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            })
        }

        return fetch(url, options)
        .then(response => {
            if (!response.ok) {
                return Promise.reject()
            }

            return response
        })
        .then(response => response.json())
        .then(json => {
            let { jwt } = json

            localStorage.setItem('token', jwt)

            return Promise.resolve()
        })
    }

    if (type === AUTH_ERROR) {
        const { status } = params
        return status === 401 || status === 403
            ? Promise.reject()
            : Promise.resolve()
    }

    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token')
        return Promise.resolve()
    }

    if (type === AUTH_GET_PERMISSIONS) {
        return Promise.resolve()
    }

    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
    }

    return Promise.reject('Uknown method')
}