import qs from 'qs'
import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    GET_MANY,
    GET_MANY_REFERENCE,
} from 'react-admin'

import SettingsStore from '../store/SettingsStore'

const apiUrl = `${SettingsStore.state.apiUrl}`

export default (type, resource, params) => {
    let url = ''

    const options = {
        headers: new Headers({
            Accept: 'application/json',
        }),
    }

    const token = localStorage.getItem('token')

    options.headers.set('Authorization', `Bearer ${token}`)

    let endpointMapping = {
        allcustomers: 'admin/allcustomers',
        customers: 'admin/customerlist',
        revenue: 'admin/revenuelist',
        bookings: 'admin/bookinglist',
        resources: 'admin/resourcelist',
        codes: 'admin/codelist',
        resource_prices: 'resourceprice',
        future_payments: 'admin/commingpayment',
        rejected_payments: 'admin/missingpayments',
        locations: 'admin/locations',
    }

    let endpoint = endpointMapping[resource]

    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination
            const { field, order } = params.sort
            let query

            switch (resource) {
                case 'resources':
                    query = {
                        sort: field,
                        order: order,
                        range: JSON.stringify([
                            (page - 1) * perPage,
                            page * perPage - 1,
                        ]),
                        location: JSON.stringify(params.filter.location),
                    }
                    break
                case 'allcustomers': break;
                default:
                    query = {
                        sort: field,
                        order: order,
                        range: JSON.stringify([
                            (page - 1) * perPage,
                            page * perPage - 1,
                        ]),
                        filter: JSON.stringify(params.filter),
                    }
                break
            }

            url = `${apiUrl}/${endpoint}?${qs.stringify(query)}`
            break
        }

        case GET_ONE: {
            switch (resource) {
                case 'resources':
                    url = `${apiUrl}/admin/resource/${params.id}`
                    break
                case 'customers':
                    url = `${apiUrl}/${endpoint}/${params.id}`
                    break
                case 'allcustomers':
                    url = `${apiUrl}/admin/allcustomers`
                    break
                default:
                    url = `${apiUrl}/${endpoint}/${params.id}`
                    break
            }

            break
        }

        case UPDATE: {
            options.headers.set('Content-type', 'application/json')
            options.method = 'PUT'
            options.body = JSON.stringify(params.data)

            switch (resource) {
                case 'resources':
                    url = `${apiUrl}/admin/updateresource`
                    break

                case 'bookings': {
                    url = `${apiUrl}/admin/cancelbooking/${params.id}`
                    break
                }

                case 'resource': {
                    url = `${apiUrl}/admin/releaseresource`
                    break
                }

                case 'locations': {
                    url = `${apiUrl}/admin/savelocation`
                    break
                }

                default:
                    url = `${apiUrl}/${endpoint}/${params.id}`
                    break
            }

            break
        }

        case GET_MANY: {
            switch (resource) {
                case 'resources':
                    const query = {
                        ids: JSON.stringify(params.ids),
                    }

                    url = `${apiUrl}/${endpoint}?${qs.stringify(query)}`
                    break
                
                default: {
                    throw new Error(`Invalid nested resource: ${resource}`)
                }
            }

            break
        }

        case GET_MANY_REFERENCE: {
            switch (resource) {
                case 'resource_prices': {
                    url = `${apiUrl}/resource/${params.id}/resourceprices`
                    break
                }

                case 'resources': {
                    url = `${apiUrl}/customer/${params.id}/customerresources`
                    break
                }

                default: {
                    throw new Error(`Invalid nested resource: ${resource}`)
                }
            }

            break
        }

        case CREATE: {
            options.headers.set('Content-type', 'application/json')
            options.method = 'POST'
            options.body = JSON.stringify(params.data)

            switch (resource) {
                case 'resource_prices': {
                    url = `${apiUrl}/resourceprice`
                    break
                }

                case 'resources': {
                    url = `${apiUrl}/admin/createresource`
                    break
                }

                case 'customers': {
                    url = `${apiUrl}/admin/createcustomer`
                    params.data['is_active'] = 1
                    break
                }

                case 'bookings': {
                    url = `${apiUrl}/admin/createbooking`
                    break
                }

                case 'locations': {
                    url = `${apiUrl}/admin/savelocation`
                    break
                }

                default: {
                    throw new Error(`Invalid POST request fro: ${resource}`)
                }
            }

            break
        }

        case DELETE: {
            options.method = 'DELETE'

            switch (resource) {
                case 'resource_prices': {
                    url = `${apiUrl}/resourceprice/${params.id}`
                    break
                }

                case 'locations': {
                    url = `${apiUrl}/admin/removelocation/${params.id}`
                    break
                }

                default: {
                    throw new Error(`Invalid DELETE request for: ${resource}`)
                }
            }

            break
        }

        case 'TOGGLE': {
            options.headers.set('Content-type', 'application/json')
            options.method = 'PUT'
            options.body = JSON.stringify(params.data)

            switch (resource) {
                case 'resources': {
                    url = `${apiUrl}/admin/changeboxstatus/${params.id}`
                    break
                }

                default: {
                    throw new Error(`Invalid TOGGLE request for: ${resource}`)
                }
            }

            break
        }

        default: {
            throw new Error(`Unsupported Data Provider request type: ${type}`)
        }
    }

    return fetch(url, options)
        .then((response) => {
            if (!response.ok) {
                let e = Error()
                e.status = response.status
                if (resource === 'customers' && type === CREATE) {
                    throw 'Kunden blev ikke oprettet, har du tjekket at emailen ikke er brugt før?'
                } else {
                    throw e
                }
            }

            return response
        })
        .then((response) => response.json())
        .then((response) => {
            // Re-write non-REST compliant responses
            let { filter } = params

            // Add ID to monthly revenue response
            if (
                resource === 'revenue' &&
                type === GET_LIST &&
                filter &&
                filter.display === 'month'
            ) {
                response = response.map((item, i) => {
                    return { ...item, id: i + 1 }
                })
            }

            // Add ID to monthly revenue response
            if (resource === 'customers' && type === GET_LIST || resource === 'customers' && type === GET_ONE) {
                response = response.map((item, i) => {
                    return { ...item, id: i + 1, customer_id: item.id }
                })
            }

            
            // Add ID to monthly revenue response
            if (resource === 'allcustomers' && type === GET_LIST || resource === 'customers' && type === GET_ONE) {
                response = response.map((item, i) => {
                    return { ...item, id: i + 1, customer_id: item.id }
                }).sort((a, b) => a.name.localeCompare(b.name))
            }


            // Filter: only resources without a customer linked
            if (
                resource === 'resources' &&
                type === GET_LIST &&
                filter &&
                filter.display === 'free'
            ) {
                response = response.filter(
                    (item, i) => item.customer_name === ''
                )
            }

            // Filter: only resources without a customer linked
            if (resource === 'customers' && type === GET_ONE && params.id) {
                response = response.find((e) => e.id === params.id)
            }

            // Filter: only resources without a customer linked
            if (resource === 'allcustomers' && type === GET_ONE && params.id) {
                response = response.map((item, i) => {
                    return { ...item, id: i + 1, customer_id: item.id }
                }).find((e) => e.customer_id === params.id);
            }

            return {
                data: response,
                total: response.length,
            }
        })
}
