import React from 'react'
import formatDate from '../../utils/formatDate'

const styles = {
    date: {
        fontSize: 14
    }
}

const DateField = ({ source, record }) => {
    let value = record[source]
    if (value) {
        let date = formatDate(value)
        return <span style={styles.date}>{date}</span>
    }
    return ''
}

export default DateField