import React from 'react'

import {
    List,
    Datagrid,
    TextField,
    CardActions,
    RefreshButton,
    ShowButton,
    CreateButton,
    FunctionField,
    ReferenceInput,
    SelectInput,
    Filter,
} from 'react-admin'
import ToggleResourceButton from '../../buttons/ToggleResourceButton'
import formatPrice from '../../utils/formatPrice'

const ResourceFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput
            label="Lokation"
            source="location"
            reference="locations"
            alwaysOn
        >
            <SelectInput optionText="name" optionValue="location_id" />
        </ReferenceInput>
    </Filter>
)

const ResourceActions = ({
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    basePath,
}) => (
    <CardActions>
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </CardActions>
)

const PriceField = ({ source, record }) => {
    let value = record[source]
    let price = formatPrice(value)
    return <span>{price} DKK</span>
}

export const ResourcesList = (props) => (
    <List
        title="Ressourcer"
        actions={<ResourceActions />}
        filters={<ResourceFilter />}
        bulkActions={false}
        perPage={9999}
        {...props}
    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="box" />
            <TextField source="location_name" label="Lokation" />
            <FunctionField
                source="volume"
                label="Rumfang"
                render={(record) => (
                    <div>
                        {record.size} m<sup>3</sup>
                    </div>
                )}
                textAlign="right"
            />
            <PriceField source="price" label="Pris" textAlign="right" />
            <ShowButton size="small" />
            {/* <EditButton /> */}
            <ToggleResourceButton />
        </Datagrid>
    </List>
)
