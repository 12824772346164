import React from 'react'

import {
    SimpleForm,
    Create,
    ReferenceInput,
    SelectInput,
    DateInput,
    required,
} from 'react-admin'

const Resources_select_name = ({ record }) => {
    switch(record.location_name) {
        case "": return record.resource_id;
        case undefined: return <span>Boks {record.box}</span>;
        default: return <span>{record.box} - {record.location_name}, {record.size}m<sup>3</sup></span>;
    } 
}

const Customer_select_name = ({ record }) => {
    switch(record.customer_id) {
        case "": return record.name;
        case undefined: return <span>{record.name}</span>;
        default: return <span>{record.customer_id} {record.name}</span>;
    } 
}

export const BookingCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>

                <DateInput label="Dato" source="from_date" validate={required()}/>

                <ReferenceInput
                    label="Kunde"
                    source="customer_id"
                    reference="allcustomers"
                    translateChoice={false}
                    validate={required()} 
                >
                    <SelectInput optionText={<Customer_select_name />} translateChoice={false} optionValue="customer_id"/>
                </ReferenceInput>

                <ReferenceInput
                    label="Rumfang"
                    source="resource_id"
                    reference="resources"
                    sort={{ field: 'resource_name', order: 'DESC' }}
                    translateChoice={false}
                    filter={{display: 'free'}}
                    validate={required()}
                >
                    <SelectInput optionText={<Resources_select_name />} translateChoice={false} optionValue="resource_id"/>
                </ReferenceInput>

            </SimpleForm>
        </Create>
    )
}