import React from 'react'
import {
    List,
    CardActions,
    RefreshButton,
    Filter,
    SelectInput,
    Datagrid,
    TextField,
    FunctionField,
    EmailField,
    CreateButton
} from 'react-admin'
import { Link }  from 'react-router-dom'
import { Edit } from '@material-ui/icons'

const CustomerActions = ({
    displayedFilters,
    sorts,
    filters,
    filterValues,
    resource,
    showFilter,
    basePath
}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}
        {sorts && React.cloneElement(sorts, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </CardActions>
)

let choices = [
    {
        value: 'inactive',
        label: 'Inaktive'
    },
    {
        value: 'active',
        label: 'Aktive'
    }
]

const CustomerFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            label="Vis kunder som er"
            source="active"
            choices={choices}
            optionText="label"
            optionValue="value"
            alwaysOn
            allowEmpty={false}
        />
    </Filter>
)

const customerDefaultFilter = { active: 'active'};


export const CustomerList = (props) => {

    const getFallback = (obj) => {
        const { state } = props.location; 
        if(state) return state; 
        return obj; 
    }

    return <List
        {...props}
        perPage={9999}
        title="Kundeliste"
        actions={<CustomerActions />}
        filters={<CustomerFilter />}
        filterDefaultValues={customerDefaultFilter}
        bulkActions={false}
        sort={{ field: 'resource_name', order: 'DESC' }}
    >
        <Datagrid>
            <TextField source="customer_id" />
            <TextField source="name" label="Navn" />
            <TextField source="resource_name" label="Box" />
            <TextField source="location_name" label="Lokation" />
            <TextField source="door_code" label="Dørkode" />
            <TextField source="telephone" label="Telefonnr." />
            <EmailField source="email" style={{ color: '#3f51b5' }} />
            <FunctionField render={(record) => (
                <Link style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 15,
                    textDecoration: 'none',
                    color: '#3f51b5',
                    fontSize: '0.8125rem'
                }} 
                to={{
                    pathname: `/customers/${record.customer_id}`,
                    state: getFallback({...record, id: record.customer_id }),
                }} >
                    <Edit/> REDIGER
                </Link>
            )} />
        </Datagrid>
    </List>
};