import React from 'react'
import { Admin, Resource } from 'react-admin'

import UserIcon from '@material-ui/icons/People'
import RevenueIcon from '@material-ui/icons/ShowChart'
import BookingIcon from '@material-ui/icons/Assignment'
import LockIcon from '@material-ui/icons/Lock'
import LocationIcon from '@material-ui/icons/LocationOn'
import PaymentIcon from '@material-ui/icons/Payment'
import ErrorIcon from '@material-ui/icons/Error'

import dataProvider from './services/ApiRestProvider'
import authProvider from './services/AuthProvider'

import { CustomerList, CustomerEdit, CustomerCreate } from './records/customer'
import { BookingList, BookingCreate } from './records/bookings'
import { RevenueList } from './records/revenue'
import {
    ResourcesList,
    ResourcesShow,
    ResourcesEdit,
    ResourcesCreate,
} from './records/resources'
import { CodesList } from './records/codes'
import {
    LocationsList,
    LocationEdit,
    LocationCreate,
} from './records/locations'
import { FuturePaymentsList } from './records/future_payments'
import { RejectedPaymentsList } from './records/rejected_payments'
import {
    ResourcePriceEdit,
    ResourcePriceCreate,
} from './records/resource_prices'

import LoginPage from './pages/LoginPage'

import danishMessages from 'ra-language-danish'

import 'typeface-roboto'

const messages = {
    da: danishMessages,
}

const i18nProvider = (locale) => messages[locale]

const App = () => (
    <Admin
        locale="da"
        i18nProvider={i18nProvider}
        title={process.env.REACT_APP_CLIENT_NAME}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
    >
        <Resource
            name="customers"
            icon={UserIcon}
            list={CustomerList}
            edit={CustomerEdit}
            create={CustomerCreate}
            options={{ label: 'Kundeliste' }}
        />
        <Resource
            name="resources"
            list={ResourcesList}
            show={ResourcesShow}
            edit={ResourcesEdit}
            create={ResourcesCreate}
            options={{ label: 'Ressourcer' }}
        />
        <Resource
            name="codes"
            list={CodesList}
            icon={LockIcon}
            options={{ label: 'Adgangskoder' }}
        />
        <Resource
            name="bookings"
            icon={BookingIcon}
            list={BookingList}
            create={BookingCreate}
            options={{ label: 'Bookinger' }}
        />
        <Resource
            name="revenue"
            icon={RevenueIcon}
            list={RevenueList}
            options={{ label: 'Økonomi' }}
        />
        <Resource
            name="resource_prices"
            edit={ResourcePriceEdit}
            create={ResourcePriceCreate}
        />
        <Resource
            name="future_payments"
            icon={PaymentIcon}
            list={FuturePaymentsList}
            options={{ label: 'Fremtidige betalinger' }}
        />
        <Resource
            name="rejected_payments"
            icon={ErrorIcon}
            list={RejectedPaymentsList}
            options={{ label: 'Afviste betalinger' }}
        />
        <Resource
            name="locations"
            icon={LocationIcon}
            list={LocationsList}
            edit={LocationEdit}
            create={LocationCreate}
            options={{ label: 'Lokationer' }}
        />

        <Resource name="allcustomers" />
    </Admin>
)

export default App
