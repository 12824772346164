import React from 'react'
import {
    SimpleForm,
    Create,
    TextInput,
    required,
    SelectInput,
} from 'react-admin'
import * as Isemail from 'isemail'
import { tldList } from '../../utils/tldList'
import countryCode from '../../utils/countryCodeList'

const validatePhone = (value) => {
    if (!value) {
        return 'Obligatorisk'
    }
    if (value.length < 8 || value.length > 8 || !/^\d+$/.test(value)) {
        return 'Fejl: mobilnummer skal være gyldigt', 'warning'
    }
    if (isNaN(Number(value))) {
        return 'Angiv et tal i formatet xx.xx'
    }
}

const validateEmail = (value) => {
    if (!value) {
        return 'Obligatorisk'
    }
    if (
        Isemail.validate(value, { errorLevel: true, tldWhitelist: tldList }) !==
        0
    ) {
        return 'Fejl: email skal være gyldigt', 'warning'
    }
}
export const CustomerCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput label="Navn" source="name" validate={required()} />

                <TextInput
                    label="Vejnavn"
                    source="address"
                    validate={required()}
                />

                <TextInput label="By" source="city" validate={required()} />

                <TextInput
                    label="Postnummer"
                    source="zipcode"
                    validate={required()}
                    type="number"
                />

                <TextInput
                    label="Email"
                    source="email"
                    validate={validateEmail}
                    type="email"
                />

                <TextInput
                    label="Mobilenummer."
                    source="telephone"
                    validate={validatePhone}
                    type="number"
                />

                <SelectInput
                    label="Landekode - mobilnummer"
                    source="country_code"
                    choices={countryCode}
                    optionText={(record) => `${record.iso} ${record.code}`}
                    optionValue="code"
                    translateChoice={false}
                    defaultValue="+45"
                />
            </SimpleForm>
        </Create>
    )
}
