import React from 'react'

import {
    SimpleForm,
    Create,
    TextInput,
    NumberInput,
    required,
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
} from 'react-admin'
import InputAdornment from '@material-ui/core/InputAdornment'

const validateMetres = (value) => {
    if (isNaN(Number(value))) {
        return 'Angiv et tal i formatet xx.xx'
    }
}

const validateCents = (value) => {
    if (!value) {
        return 'Obligatorisk'
    }
    if (Number(value) % 1 !== 0) {
        return 'Angiv et helt tal'
    }
}

const calcVolume = (formData) => {
    const { height, width, length } = formData
    if (
        height &&
        !isNaN(Number(height)) &&
        width &&
        !isNaN(Number(width)) &&
        length &&
        !isNaN(Number(length))
    ) {
        const volume = height * width * length
        if (volume % 1 === 0) {
            return volume
        } else {
            return volume.toFixed(2)
        }
    }
}

const adornmentMetres = {
    endAdornment: (
        <InputAdornment position="end" component="span">
            <span className="input-adornment">meter</span>
        </InputAdornment>
    ),
}

const adornmentVolume = {
    endAdornment: (
        <InputAdornment position="end" component="span">
            <span className="input-adornment">
                m<sup>3</sup>
            </span>
        </InputAdornment>
    ),
}

const adornmentCents = {
    endAdornment: (
        <InputAdornment position="end" component="span">
            <span className="input-adornment">øre</span>
        </InputAdornment>
    ),
}

export const ResourcesCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput label="Box" source="name" validate={required()} />
                <TextInput
                    label="Højde"
                    source="height"
                    validate={validateMetres}
                    InputProps={adornmentMetres}
                    parse={(v) => v.replace(',', '.')}
                />
                <TextInput
                    label="Bredde"
                    source="width"
                    validate={validateMetres}
                    InputProps={adornmentMetres}
                    parse={(v) => v.replace(',', '.')}
                />
                <TextInput
                    label="Længde"
                    source="length"
                    validate={validateMetres}
                    InputProps={adornmentMetres}
                    parse={(v) => v.replace(',', '.')}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData && (
                            <TextInput
                                label="Rumfang"
                                source="volume"
                                {...rest}
                                validate={validateMetres}
                                defaultValue={calcVolume(formData)}
                                InputProps={adornmentVolume}
                                parse={(v) => v.replace(',', '.')}
                            />
                        )
                    }
                </FormDataConsumer>
                <NumberInput
                    label="Pris"
                    source="price"
                    validate={validateCents}
                    InputProps={adornmentCents}
                />
                <ReferenceInput
                    label="Lokation"
                    source="location_id"
                    reference="locations"
                    validate={required()}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
}
