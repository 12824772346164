export default {
    state: {
        apiUrl: process.env.NODE_ENV === 'development'
        ? 'https://pulterbox.api.depotbook.dk/api'
        : process.env.REACT_APP_API_URL
    },
    setApiUrl (url) {
        if (url !== '') {
            //this.state.apiUrl = url
            this.state.apiUrl = process.env.REACT_APP_API_URL
        }
    }
}
