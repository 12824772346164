import React from 'react'

import {
    Datagrid,
    TextField,
    TabbedShowLayout,
    Tab,
    Show,
    ReferenceManyField,
    EditButton,
    DeleteButton,
    FunctionField,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import formatPrice from '../../utils/formatPrice'
import formatDate from '../../utils/formatDate'

const ResourceTitle = ({ record }) => {
    return <span>Resource {record ? `Box #${record.box}` : ''}</span>
}

const CreateRelatedPriceButton = ({ record }) => (
    <Button
        component={Link}
        variant="outlined"
        color="primary"
        size="small"
        style={{ marginTop: '2em' }}
        to={{
            pathname: '/resource_prices/create',
            state: { record: { resource_id: record.id } },
        }}
    >
        Opret ny pris
    </Button>
)

export const ResourcesShow = (props) => (
    <Show title={<ResourceTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Oversigt">
                <TextField source="id" label="Id" />
                <TextField source="name" label="Box" />
                <TextField source="height" label="Højde" />
                <TextField source="width" label="Bredde" />
                <TextField source="length" label="Længde" />
                <FunctionField
                    source="volume"
                    label="Rumfang"
                    render={(record) => (
                        <div>
                            {record.volume} m<sup>3</sup>
                        </div>
                    )}
                />
            </Tab>
            <Tab label="Priser">
                <CreateRelatedPriceButton />
                <ReferenceManyField
                    label=""
                    reference="resource_prices"
                    target="id"
                >
                    <Datagrid>
                        <TextField source="id" />
                        <FunctionField
                            source="price"
                            label="Price"
                            render={(record) => (
                                <div>{formatPrice(record.price)} DKK</div>
                            )}
                        />
                        <FunctionField
                            source="active_from"
                            label="Aktiv fra"
                            render={(record) => (
                                <div>{formatDate(record.active_from)}</div>
                            )}
                        />
                        <EditButton />
                        <DeleteButton redirect="1" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)

