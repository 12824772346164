import React from 'react'
import {
    List,
    CardActions,
    RefreshButton,
    TextInput,
    Filter,
    SelectInput
} from 'react-admin'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import formatPrice from '../utils/formatPrice';
import formatDate from '../utils/formatDate'

const RevenueActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter
}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}
        <RefreshButton />
    </CardActions>
)

let choices = [
    {
        value: 'list',
        label: 'Liste'
    },
    {
        value: 'month',
        label: 'Månedlig'
    }
]

const revenueDefaultFilter = {
    from: '',
    to: '',
    display: 'list'
}

const RevenueFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Fra" source="from" type="date" InputLabelProps={{ shrink: true }} alwaysOn />
        <TextInput label="Til" source="to" type="date" InputLabelProps={{ shrink: true }} alwaysOn />
        <SelectInput label="Visning" source="display" choices={choices} optionText="label" optionValue="value" alwaysOn />
    </Filter>
)

const RevenueGrid = ({ ids, data, filterValues, isLoading }) => {
    let { display } = filterValues

    if (!ids.length) return <p></p>

    const Month = (props) => {
        // Do not try to display data that doesn't fit in this filter view
        // Instead display the compatible list component
        if (isLoading) return <List />

        let months = ids
        .map(id => data[id])
        .map(month => {
            return {
                ...month,
                month: month.month.substr(-2),
                year: month.month.substr(0, 4)
            }
        })

        let total = 0

        total = months.reduce((sum, month, i) => sum + months[i]['revenue'], 0)

        let danishMonths = {
            '01': 'Januar',
            '02': 'Februar',
            '03': 'Marts',
            '04': 'April',
            '05': 'Maj',
            '06': 'Juni',
            '07': 'Juli',
            '08': 'August',
            '09': 'September',
            '10': 'Oktober',
            '11': 'November',
            '12': 'December',
        }

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Måned
                        </TableCell>
                        <TableCell>
                            Beløb
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {months.map(month => (
                        <TableRow key={month.id}>
                            <TableCell>
                                { danishMonths[month.month] } { month.year }
                            </TableCell>
                            <TableCell numeric>
                                {(month.revenue/100).toLocaleString()} DKK
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell>
                            <b>Total</b>
                        </TableCell>
                        <TableCell numeric>
                            {(total/100).toLocaleString()} DKK
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    const List = (props) => {
        let total = ids.reduce((accumulated, id) => {
            return accumulated + data[id]['amount']
        }, 0)

        return (
            <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>
                            Navn
                        </TableCell>
                        <TableCell>
                            Box
                        </TableCell>
                        <TableCell>
                            Lokation
                        </TableCell>
                        <TableCell>
                            Dato
                        </TableCell>
                        <TableCell>
                            Order nr.
                        </TableCell>
                        <TableCell>
                            Beløb
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ids.map(id => (
                        <TableRow key={id}>
                            <TableCell>
                                {data[id]['name']}
                            </TableCell>
                            <TableCell>
                                {data[id]['box']}
                            </TableCell>
                            <TableCell>
                                {data[id]['location']}
                            </TableCell>
                            <TableCell>
                                { formatDate(data[id]['date']) }
                            </TableCell>
                            <TableCell>
                                {data[id]['id']}
                            </TableCell>
                            <TableCell numeric>
                                {( formatPrice(data[id]['amount']) )} DKK
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan="3">
                            <strong>Total</strong>
                        </TableCell>
                        <TableCell numeric>
                            <strong>{( formatPrice(total) )} DKK</strong>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    if (display === 'list' || !display) {
        return <List />
    }

    if (display === 'month') {
        return <Month />
    }
}

export const RevenueList = (props) => (
    <List {...props} title="Økonomi" actions={<RevenueActions />} perPage={9999} filters={<RevenueFilter/>} filterDefaultValues={revenueDefaultFilter}>
        <RevenueGrid />
    </List>
)