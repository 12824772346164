import {
    showNotification,
    fetchEnd,
    fetchStart
} from 'react-admin'
import {
    Button
} from '@material-ui/core'

import { push } from 'react-router-redux'

import { connect } from 'react-redux'

import { PropTypes } from 'prop-types'

import React, { Component } from 'react'

import dataProvider from '../services/ApiRestProvider'

class ToggleResourceButton extends Component {
    constructor (props) {
        super(props)

        this.state = {
            locked: props.record.is_locked === 1
        }
    }

    handleClick = () => {
        const { record, showNotification, fetchStart, fetchEnd } = this.props
        const updatedRecord = { ...record }

        fetchStart()

        dataProvider('TOGGLE', 'resources', { id: record.id, data: updatedRecord })
            .then((update) => {
                this.setState({
                    locked: update.data.is_locked === 1
                })
                showNotification('Ressourcen blev ændret')
                push('/resources')
            })
            .catch((e) => {
                console.error(e)
                showNotification('Error: Fejl ved ændring af ressource', 'warning')
            })
            .finally(fetchEnd)
    }

    render () {
        return (
            <Button onClick={this.handleClick} variant="outlined" size="small" color="primary">
                { this.state.locked ? 'Genåbn' : 'Lås' } box
            </Button>
        )
    }
}

ToggleResourceButton.propType = {
    fetchStart: PropTypes.func,
    fetchEnd: PropTypes.func,
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func
    ])
}

ToggleResourceButton.defaultProps = {
    redirect: 'list'
}

export default connect(null, {
    showNotification,
    fetchStart,
    fetchEnd,
    push,
    redirect: 'list'
})(ToggleResourceButton)