import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userLogin, Notification } from 'react-admin'

class LoginPage extends Component {

    submit = (e) => {
        e.preventDefault()

        const credentials = {
            username: document.getElementById('username').value,
            password: document.getElementById('password').value
        }

        this.props.userLogin(credentials)
    }

    render () {
        return (
            <form onSubmit={this.submit} id="login">
                <h2>
                    Booking admin
                </h2>
                <input type="text" name="username" id="username" placeholder="Email"/>
                <input type="password" name="password" id="password" placeholder="Password"/>
                <input type="submit" value="Log ind"/>
                <Notification/>
            </form>
        )
    }

}

export default connect(undefined, { userLogin })(LoginPage)