import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    CardActions,
    RefreshButton,
} from 'react-admin'

import DateField from '../components/fields/dateField'
import formatPrice from '../utils/formatPrice'

const RejectedPaymentsActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
}) => (
    <CardActions>
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        <RefreshButton />
    </CardActions>
)

const AmountField = ({ source, record = {} }) => {
    let value = record[source]

    if (value === null) {
        return <span></span>
    }

    return formatPrice(value) + ' DKK'
}

export const RejectedPaymentsList = (props) => (
    <List
        {...props}
        perPage={9999}
        title="Afviste betalinger"
        actions={<RejectedPaymentsActions />}
        bulkActions={false}
    >
        <Datagrid>
            {/* <ReferenceField
                source="resource_id"
                reference="resources"
                linkType="show"
                label="Box"
                sortBy="box"
            >
                <FunctionField render={(record) => record.box} />
            </ReferenceField> */}
            <TextField source="customer_name" label="Kunde" />
            <TextField source="email" label="Email" />
            <TextField source="customer_card_no" label="Kortnummer" />
            <TextField source="customer_card_expire" label="Udløbsdato" />
            {/* <TextField source="phone" label="Telefon" /> */}
            <AmountField source="amount" label="Beløb" />
            <DateField source="pay_date" label="Betalingsdato" />
        </Datagrid>
    </List>
)
