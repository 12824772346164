import React from 'react'
import  { Redirect } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import {
    showNotification,
    SimpleForm,
    TextInput,
    required,
    DisabledInput,
    refreshView
} from 'react-admin'
import { connect } from 'react-redux'
import SettingsStore from '../../store/SettingsStore'
import * as Isemail from 'isemail';
import { tldList } from '../../utils/tldList';

const CustomerEditView = (props) => {
    const apiUrl = `${SettingsStore.state.apiUrl}`

    const { state } = props.location;
    const { history } = props;
    
    if(!state) return <Redirect to='/#/customers'  />;
    if(!state) return <Redirect to='/#/customers'  />;

    const save = (data) => {
        const { showNotification, refreshView } = props;

        if(data.telephone.length < 8 || data.telephone.length > 8 || !/^\d+$/.test(data.telephone)) {
            showNotification('Fejl: mobilnummer skal være gyldigt', 'warning');
            return;
        }

        if(Isemail.validate(data.email, {errorLevel: true, tldWhitelist: tldList}) !== 0) {
            showNotification('Fejl: email skal være gyldigt', 'warning');
            return;
        }

        const options = {
            headers: new Headers({
                Accept: 'application/json',
            }),
        }
        options.headers.set('Content-type', 'application/json')
        options.method = 'PUT'
        options.body = JSON.stringify(data)

        const token = localStorage.getItem('token')
        options.headers.set('Authorization', `Bearer ${token}`)
        const url = `${apiUrl}/admin/updatecustomer`

        fetch(url, options)
        .then((response) => {
            if (!response.ok) {
                showNotification('Fejl: Kunne ikke opdatere kunden', 'warning')
            }
            return response
        })
        .catch()
        .then(() => {
            showNotification('Kunden blev opdateret', 'succes');
            history.push({pathname: '/customers', state:{...state, ...data}});
            refreshView();
        })
     
    }

    return (
        <SimpleForm defaultValue={{...state}} save={save}>
            <DisabledInput label="Id" source="id" />
            <TextInput label="Navn" source="name" validate={required()} />
            <TextInput label="Email" source="email" validate={required()} type="email" />
            <TextInput label="Telefonnr." source="telephone" validate={required()} type="number" />
            <DisabledInput label="Id" source="resource_name" />
            <DisabledInput label="Id" source="location_name" />
            <DisabledInput label="Id" source="door_code" />
        </SimpleForm>
    )
}

CustomerEditView.propTypes = {
    showNotification: PropTypes.func,
    refreshView: PropTypes.func,
}

export const CustomerEdit = connect(null, {
    showNotification,
    refreshView,
})(CustomerEditView)