import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    CardActions,
    RefreshButton,
    ReferenceField,
    FunctionField
} from 'react-admin'

import DateField from '../components/fields/dateField'
import formatPrice from '../utils/formatPrice'

const FuturePaymentsActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter
}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}
        <RefreshButton />
    </CardActions>
)

const AmountField = ({ source, record = {}}) => {
    let value = record[source]

    if (value === null) {
        return <span></span>
    }

    return (
        <span>{(formatPrice(value))} DKK</span>
    )
}

export const FuturePaymentsList = (props) => (
    <List {...props} perPage={9999} title="Fremtidige betalinger" actions={<FuturePaymentsActions />} bulkActions={false}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="resource_id" reference="resources" linkType="show" label="Box" sortBy="box">
                <FunctionField render={record => record.box } />
            </ReferenceField>
            <TextField source="customer_name" label="Kunde" />
            <AmountField source="price" label="Beløb" />
            <DateField source="pay_date" label="Betalingsdato" />
        </Datagrid>
    </List>
)