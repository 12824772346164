import {
    showNotification,
    fetchEnd,
    fetchStart,
    UPDATE,
    refreshView,
} from 'react-admin'

import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions
} from '@material-ui/core'

import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import React, { Component } from 'react'
import dataProvider from '../services/ApiRestProvider'
import formatDate from '../utils/formatDate'
import dayjs from 'dayjs'

class ReleaseResourceButton extends Component {

    constructor(props) {
        super(props)

        const today = new Date();
        const formattedToday = String(today.getFullYear()) + "-" + String(today.getMonth() + 1).padStart(2, '0') + "-" + String(today.getDate()).padStart(2, '0');

        this.state = {
            active: props.record.to === null,
            before_today: new Date(props.record.to) > new Date(),
            release_date: formattedToday,
            dialogIsOpen: false,
            releaseError: false,
            releaseErrorText: ''
        }
    }

    handleReleaseValidate = () => {
        const { release_date } = this.state
        if (release_date === '') {
            return 'Datoen kan ikke være tom'
        }
        else if (dayjs(release_date).isBefore(dayjs(), 'day')) {
            return 'Datoen kan ikke være før dags dato'
        }
    }

    handleReleaseClick = () => {
        const { record, showNotification, refreshView, fetchStart, fetchEnd } = this.props
        const updatedRecord = { resource_id: record.resource_id, release_date: this.state.release_date }

        var error = ''
        if (error === this.handleReleaseValidate()) {
            this.setState({
                releaseError: true,
                releaseErrorText: error
            })
            return
        }

        fetchStart()

        dataProvider(UPDATE, 'resource', { id: record.resource_id, data: updatedRecord })
            .then((update) => {
                this.setState({
                    active: update.data.to === null
                })
                if (update.data.booking_releasedate) {
                    const releaseDate = formatDate(update.data.booking_releasedate)
                    showNotification('Ressource bliver frigivet den ' + releaseDate)
                }
                else {
                    showNotification('Ressource blev frigivet')
                }
                this.handleDialogClose()
                push('/bookings')
                refreshView()
            })
            .catch((e) => {
                console.error(e)
                showNotification('Error: Ressource blev ikke frigivet', 'warning')
            })
            .finally(fetchEnd)
    }

    handleDialogClickOpen = () => {
        this.setState({ dialogIsOpen : true})
    }

    handleDialogClose = () => {
        this.setState({
            dialogIsOpen: false,
            releaseError: false,
            releaseErrorText: ''
        })
    }

    render() {
        let { before_today, releaseError, releaseErrorText } = this.state

        if (before_today) {
            return (
                <React.Fragment>
                    <Button
                        onClick={this.handleDialogClickOpen}
                        color="primary"
                        size="small"
                        variant="outlined"
                    >
                        Frigiv før tid
                    </Button>
                    <Dialog
                        open={this.state.dialogIsOpen}
                        onClose={this.handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                    >
                        <DialogContent>
                            <TextField
                                id="cancel_date"
                                label="Dato"
                                type="date"
                                value={this.state.release_date}
                                onChange={event =>
                                    this.setState({
                                        release_date: event.target.value
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true
                                }}
                                error={releaseError}
                                helperText={releaseError && (releaseErrorText)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleDialogClose}
                                color="primary"
                            >
                                Luk
                            </Button>
                            <Button
                                onClick={this.handleReleaseClick}
                                variant="contained"
                                color="primary"
                            >
                                Frigiv
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment/>
            )
        }
    }
}

ReleaseResourceButton.propTypes = {
    fetchStart: PropTypes.func,
    fetchEnd: PropTypes.func,
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func
    ])
}

ReleaseResourceButton.defaultProps = {
    redirect: 'list'
}

export default connect(null, {
    showNotification,
    fetchStart,
    fetchEnd,
    push,
    refreshView,
    redirect: 'list'
})(ReleaseResourceButton)