import React from 'react'
import {
    TextInput,
    List,
    Datagrid,
    TextField,
    Filter,
    CardActions,
    RefreshButton,
    ReferenceField,
    FunctionField,
    CreateButton
} from 'react-admin'

import CancelBookingButton from '../../buttons/CancelBookingButton';
import ReleaseResourceButton from '../../buttons/ReleaseResourceButton';
import DateField from '../../components/fields/dateField'
import formatDate from '../../utils/formatDate'

const BookingActions = ({
    basePath,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter
}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </CardActions>
)

const BookingFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Fra" source="from" type="date" InputLabelProps={{ shrink: true }} alwaysOn />
        <TextInput label="Til" source="to" type="date" InputLabelProps={{ shrink: true }} alwaysOn />
    </Filter>
)

const CustomToTextField = ({ source, record = {} }) => {
    let value = record[source]

    if (value === null) {
        return <CancelBookingButton record={record} />
    }

    return (
        <span className="font-size-std">{formatDate(value)}</span>
    )
}

const bookingDefaultFilter = {
    from: '',
    to: ''
}

export const BookingList = (props) => (
    <List {...props} perPage={9999} title="Bookinger" actions={<BookingActions />} filters={<BookingFilter/>} filterDefaultValues={bookingDefaultFilter} bulkActions={false}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="resource_id" reference="resources" linkType="show" label="Box" sortBy="box">
                <FunctionField render={record => record.box } />
            </ReferenceField>
            {/* <ReferenceField source="customer_id" reference="allcustomers" linkType="show" label="Kunde">
                <FunctionField render={record => record.customer_id } />
            </ReferenceField>             */}
            <DateField source="from" label="Fra" />
            <CustomToTextField source="to" label="Til" />
            <ReleaseResourceButton />
        </Datagrid>
    </List>
)