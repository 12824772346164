import React from 'react'

import {
    Create,
    Edit,
    SimpleForm,
    DisabledInput,
    DateInput,
    NumberInput,
    required
} from 'react-admin'

import InputAdornment from '@material-ui/core/InputAdornment'

const redirect = (basePath, id, data) => `/resources/${data.resource_id}/show/1`

const adornmentCents = {
    endAdornment: (
        <InputAdornment position="end" component="span">
            <span className="input-adornment">øre</span>
        </InputAdornment>
    )
}

export const ResourcePriceCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={redirect}>
            <NumberInput
                source="price"
                label="Pris" validate={required()}
                InputProps={adornmentCents}
            />
            <DateInput source="active_from" label="Aktiv fra" validate={required()} />
        </SimpleForm>
    </Create>
)

export const ResourcePriceEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect={redirect}>
            <DisabledInput label="Id" source="id" />
            <NumberInput
                source="price"
                label="Pris" validate={required()}
                InputProps={adornmentCents}
            />
            <DateInput source="active_from" label="Aktiv fra" validate={required()} />
        </SimpleForm>
    </Edit>
)