import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    CardActions,
    RefreshButton,
    EditButton,
    SimpleForm,
    required,
    Edit,
    Create,
    TextInput,
    CreateButton,
    DisabledInput,
    BooleanField,
    Toolbar,
    DeleteButton,
    SaveButton,
    SelectInput,
} from 'react-admin'

const LocationsActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
}) => (
    <CardActions>
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        <RefreshButton />
        <CreateButton basePath={basePath} />
    </CardActions>
)

const BooleanNumField = ({ record = {}, source }) => {
    let theRecord = { ...record }

    theRecord[source + 'Num'] = !!parseInt(record[source])

    return <BooleanField record={theRecord} source={source + 'Num'} />
}

export const LocationsList = (props) => (
    <List
        {...props}
        perPage={9999}
        title="Lokationer"
        actions={<LocationsActions />}
        bulkActions={false}
    >
        <Datagrid>
            {/* <TextField source="id" /> */}
            <BooleanNumField source="is_active" label="Aktiv" />
            <TextField source="name" label="Name" />
            <TextField source="firm_email" label="Email" />
            <TextField source="firm_phone" label="Telefon" />
            <EditButton size="small" />
        </Datagrid>
    </List>
)

const EditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton undoable={false} />
    </Toolbar>
)

export const LocationEdit = (props) => (
    <Edit toolbar={<EditToolbar />} {...props}>
        <SimpleForm>
            <DisabledInput source="id" label="Id" />
            <TextInput label="Navn" source="name" validate={required()} />
            <TextInput label="Adresse" source="address" />
            <TextInput label="Postnummer" source="zipcode" />
            <TextInput label="By" source="city" />
            <TextInput label="Email" source="firm_email" />
            <TextInput label="Telefon" source="firm_phone" />
            <TextInput label="Kontakttider" source="firm_contact_time" />
            <TextInput label="CVR" source="firm_reg_no" />
            <TextInput label="Beskrivelse" source="description" />
            <SelectInput
                label="Aktiv"
                source="is_active"
                optionText="label"
                optionValue="value"
                choices={[
                    {
                        label: 'Aktiv',
                        value: 1,
                    },
                    {
                        label: 'Inaktiv',
                        value: 0,
                    },
                ]}
            />
        </SimpleForm>
    </Edit>
)

export const LocationCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput label="Navn" source="name" validate={required()} />
                <TextInput label="Adresse" source="address" />
                <TextInput label="Postnummer" source="zipcode" />
                <TextInput label="By" source="city" />
                <TextInput label="Email" source="firm_email" />
                <TextInput label="Telefon" source="firm_phone" />
                <TextInput label="Kontakttider" source="firm_contact_time" />
                <TextInput label="CVR" source="firm_reg_no" />
                <TextInput label="Beskrivelse" source="description" />
                <SelectInput
                    label="Aktiv"
                    source="is_active"
                    optionText="label"
                    optionValue="value"
                    choices={[
                        {
                            label: 'Aktiv',
                            value: 1,
                        },
                        {
                            label: 'Inaktiv',
                            value: 0,
                        },
                    ]}
                />
            </SimpleForm>
        </Create>
    )
}
