import {
    showNotification,
    fetchEnd,
    fetchStart,
    UPDATE,
    refreshView
} from 'react-admin'
import {
    Button
} from '@material-ui/core'

import { push } from 'react-router-redux'

import { connect } from 'react-redux'

import { PropTypes } from 'prop-types'

import React, { Component } from 'react'

import dataProvider from '../services/ApiRestProvider'

class CancelBookingButton extends Component {
    constructor(props) {
        super(props)

        this.state = {
            active: props.record.to === null
        }
    }

    handleClick = () => {
        const { record, showNotification, fetchStart, fetchEnd, refreshView } = this.props
        const updatedRecord = { ...record, cancelled: true }
        fetchStart()

        dataProvider(UPDATE, 'bookings', { id: record.id, data: updatedRecord })
            .then((update) => {
                this.setState({
                    active: update.data.to === null
                })
                showNotification('Booking blev annulleret')
                push('/bookings')
                refreshView()
            })
            .catch((e) => {
                console.error(e)
                showNotification('Error: Booking blev ikke annulleret', 'warning')
            })
            .finally(fetchEnd)
    }

    render() {
        let { active } = this.state

        if (active) {
            return <Button
                onClick={this.handleClick}
                color="primary"
                size="small"
                variant="outlined"
            >
                Annuller booking
            </Button>
        } else {
            return <span>{ this.props.record.to }</span>
        }
    }
}

CancelBookingButton.propTypes = {
    fetchStart: PropTypes.func,
    fetchEnd: PropTypes.func,
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func
    ])
}

CancelBookingButton.defaultProps = {
    redirect: 'list'
}

export default connect(null, {
    showNotification,
    fetchStart,
    fetchEnd,
    push,
    refreshView,
    redirect: 'list'
})(CancelBookingButton)