import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    CardActions,
    RefreshButton,
    ReferenceField,
    FunctionField
} from 'react-admin'

const CodesActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter
}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}
        <RefreshButton />
    </CardActions>
)

export const CodesList = (props) => (
    <List {...props} perPage={9999} title="Adgangskoder" actions={<CodesActions />} bulkActions={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="code" label="Adgangskode" />
            <ReferenceField source="resource_id" reference="resources" linkType="show" label="Box" sortBy="box">
                <FunctionField render={record => record.box } />
            </ReferenceField>
        </Datagrid>
    </List>
)